/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { Container, Box, Flex, NavLink } from '@theme-ui/components'
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import { Grid, Divider, Button, Card, Text, Message} from '@theme-ui/components';
import NavBar from '../components/NavBar';
import { graphql } from 'gatsby';
import { Link as GatsbyLink } from 'gatsby'
import { Link } from '@theme-ui/components'
import Seo from '../components/seo';
import Footer from '../components/footer';



const index = ({ data }) => {
  const posts = data.allMdx.nodes
  console.log(posts);
  return (
    <div>
    <Seo />
      <NavBar />
      <div sx={{
      width: '100vw',
      minWidth: '100vw',
      minHeight: '90vh',
      backgroundColor: 'primary',
      position: 'relative',
    }}>

    <StaticImage
            src="../images/ame-hero-v2a.png"
            alt=""
            objectFit="cover"
            objectPosition="100% 0%"
            style={{ position: "absolute", zIndex: 1, height: '100%', width: '100%' }}
        />

        <div sx={{
          backgroundColor: "background",
          opacity: 0.8,
        height: "100%",
        width: "100%",
        position: "absolute",
        zIndex: 2,
        }}
        >
        </div>

  
    <Container sx={{
      height: "100%",
        zIndex: 100,
        position: "relative",
    }}>
    <Box py={6} color="white">
    <Grid gap={2} columns={[1, 1, 3]}>
    <Box>
    <h1
    sx={{
      color: 'white',
      fontSize: '48px',
      fontWeight: '500',
      lineHeight: '1',
    }}>
      <span sx={{
        color: 'secondary',
      }}> Knowledge is Power.</span> Sharing knowledge empowers us all.
    </h1>
    <Divider sx={{
      backgroundColor: 'primary',
    }}/>
    </Box>
      <Box></Box>
    </Grid>
    
    <h2>
    Empowering the world's asset guardians with quality, accessible, affordable knowledge drives us to continually update our body of knowledge.
    </h2>
    <p>
    Subscribers to AME Systems are the direct benefactors of our unquenchable thirst for knowledge. Watch your inbox or check back often as new courses are added and existing updated. The AME Essentials are short collections of technology specific topics from ultrasound and vibration, infrared and motor circuit testing, alignment and balancing, and reliability centred maintenance. Each course features the foundational, applications, and practical knowledge every technician needs to know before leaping deeper into certifications. Whether you are a seasoned professional or an engineering graduate rookie, the AME Essentials series has something for everyone.
    </p>
    
    <Button as={GatsbyLink} to='/contact' mr={2}>Want to be an Instructor?</Button>
    </Box>
    </Container>
    </div>
    <Container>
    <Box py={4}>
    <Text py={3} as="h2">
      New Releases
    </Text>
    <Grid columns={[1, 1, 3]}>
    {posts.map(post => {
      if(post.frontmatter.contentCategory === 'Course' && post.frontmatter.newrelease === true) {
          return (
            
            
            <Card key={post.slug}
              sx={{
                maxWidth: '100%',
                padding: 1,
                  borderRadius: 2,
                  border: '1px solid',
                  borderColor: 'primary',
              }}>
              <GatsbyImage image={getImage(post.frontmatter.featuredImage)}
              />
              <Text as="h3" p={2} sx={{
                color: 'secondary',
              }}>{post.frontmatter.title} - {post.frontmatter.subTitle}</Text>
              <Text as="p" p={2} sx={{
                color: 'text',
                fontSize: '12px',
              }}>{post.frontmatter.company}</Text>
              <Text as="p" p={2} sx={{
                color: 'text',
              }}>{post.frontmatter.excerpt}</Text>
                    <Link p={2} sx={{ color: 'primary' }} to={'/'+post.slug} as={GatsbyLink}>
        Read More...
      </Link>
              
              </Card>
          )
      }
        })}

        
    </Grid>
    </Box>
    
    </Container>
    <Container>
    <Box py={4}>
    <Text py={3} as="h2">
      Featured Courses
    </Text>
    <Grid columns={[1, 1, 3]}>
    {posts.map(post => {
      if(post.frontmatter.contentCategory === 'Course' && post.frontmatter.featured === true) {
          return (
            
            
            <Card key={post.slug}
              sx={{
                maxWidth: '100%',
                padding: 1,
                  borderRadius: 2,
                  border: '1px solid',
                  borderColor: 'primary',
              }}>
              <GatsbyImage image={getImage(post.frontmatter.featuredImage)}
              />
              <Text as="h3" p={2} sx={{
                color: 'secondary',
              }}>{post.frontmatter.title} - {post.frontmatter.subTitle}</Text>
              <Text as="p" p={2} sx={{
                color: 'text',
                fontSize: '12px',
              }}>{post.frontmatter.company}</Text>
              {post.frontmatter.message && <Message sx={{
                backgroundColor: 'background',
                borderColor: '#00ff00',
                color: 'text',
                marginBottom: '10px',
                borderTop: '1px solid #00ff00',
                borderBottom: '1px solid #00ff00',
                borderRight: '1px solid #00ff00',
                borderLeftWidth: '10px'}}>
                <Text as="p" sx={{
                  fontWeight: '700',
                  fontSize: '12px',
                  color: '#00ff00'
                }}>
                  {post.frontmatter.messages.map(info => {
                    return (
                      <>
                      {info.alertTitle} - {info.alertSubTitle} - {info.alertDate}
                      </>
                      )
                  })}
                </Text>
                <Divider my={1} sx={{
                  backgroundColor: '#00ff00',
                }}/>
                <Text as="p" sx={{
                  fontSize: '12px',
                }}>
                  {post.frontmatter.messages.map(info => {
                    return (
                      <>
                      {info.alertMessage}
                      </>
                      )
                  })}
                </Text>
                </Message>}
              <Text as="p" p={2} sx={{
                color: 'text',
              }}>{post.frontmatter.excerpt}</Text>
                    <Link p={2} sx={{ color: 'primary' }} to={'/'+post.slug} as={GatsbyLink}>
        Read More...
      </Link>
              
              </Card>
          )
      }
        })}

        
    </Grid>
    </Box>
    
    </Container>
    <Footer />
    </div>
  )
}

export default index

export const pageQuery = graphql`
  query {
    allMdx(sort: {fields: [frontmatter___date], order: DESC}) {
      nodes {
        id
        slug
        excerpt(pruneLength: 250)
        frontmatter {
          category
          excerpt
          author
          company
          contentCategory
          featured
          newrelease
          featuredImage {
            childImageSharp {
              gatsbyImageData(
                width: 1200
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          title
          subTitle
          message
          messages {
            alertMessage
            alertTitle
            alertSubTitle
            alertDate
          }
          date(formatString: "YYYY MMMM Do")
          modules {
            moduleNumber
            title
            content
          }
        }
      }
    }
  }
`
